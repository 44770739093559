<template>
  <div class="profile-settings d-flex flex-row">
    <!-- <profile-menu class="menu align-self-start" separated /> -->
    <div class="content ">
      <router-view />
    </div>
    <snackbar/>
  </div>
</template>

<script>
// import ProfileMenu from "../ProfileMenu";
import Snackbar from "../layout/Snackbar";


export default {
  data() {
    return {

    };
  },
  components: {
    // ProfileMenu,
    Snackbar
  },
  mounted() {
  
    
  }
};
</script>

<style lang="scss" scoped>
.profile-settings {
  // padding: 4rem 4rem 4rem 12rem;
}

.menu {
  // padding: 1rem;
  width: 220px;

  border-radius: 0.5rem;
}

.content {
  // margin-left: 2rem;
}
</style>
